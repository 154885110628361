












































































































import { Component, Vue } from "vue-property-decorator";
import AnalysisSvg from "@/components/AnalysisSvg.vue";
// import CustomButton from '@/components/CustomButton.vue';
import BaseModal from "@/components/BaseModal.vue";
import roadBendRightBottom from "@/assets/svg/road-bend-right-bottom.svg";
import Categories from "@/components/SystemCostFramework/Categories.vue";
import CategoriesDetails from "@/components/SystemCostFramework/CategoriesDetails.vue";
// TEST
@Component({
    components: {
        AnalysisSvg,
        Categories,
        CategoriesDetails,
        BaseModal,
        // CustomButton
    },
})
export default class HeadlineAnalysisNew extends Vue {
    public roadBendRightBottom = roadBendRightBottom;
    public findOutDetails = false;
    public showMoreDetails = false;

    showModal() {
        this.showMoreDetails = true;
    }

    findOutMore() {
        this.findOutDetails = true;
    }

    backToDefaultView() {
        this.findOutDetails = false;
    }
    closeModal() {
        this.showMoreDetails = false;
    }
}
