<script>
/* eslint-disable */
import Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Annotation from 'highcharts/modules/annotations.js'

More(Highcharts);
Annotation(Highcharts);


const HighchartsVueComponent = {
  template: '<div ref="chart"></div>',
  render: createElement => createElement('div', { ref: 'chart' }),
  props: {
    constructorType: {
      type: String,
      default: 'chart'
    },
    initialOptions: {
      type: Object,
      required: true
    },
    callback: Function,
    updateArgs: {
      type: Array,
      default: () => [true, true]
    }
  },
  data() {
    return {
      seriesDataChange: false
    };
  },
  methods: {
    updateOptions(newOptions) {
      this.chart.update(Object.assign({}, newOptions), ...this.updateArgs);
    },
    // destroyChart() {
    //   this.chart.destroy();
    // },
    makeChart(options) {
      this.chart = Highcharts[this.constructorType](
        this.$refs.chart,
        Object.assign({}, options),
        this.callback ? this.callback : null
      );
    },
    fullScreen() {
      Highcharts.FullScreen = function(container) {
        this.init(container.parentNode); // main div of the chart
      };

      Highcharts.FullScreen.prototype = {
        init(container) {
          if (container.requestFullscreen) {
            container.requestFullscreen();
          } else if (container.mozRequestFullScreen) {
            container.mozRequestFullScreen();
          } else if (container.webkitRequestFullscreen) {
            container.webkitRequestFullscreen();
          } else if (container.msRequestFullscreen) {
            container.msRequestFullscreen();
          }
        }
      };
      this.chart.fullscreen = new Highcharts.FullScreen(this.chart.container);
    },
    exportChart() {
      this.chart.exportChartLocal();
    },
    printChart() {
      this.chart.print();
    },
    drawChart() {
      this.chart.update(Object.assign({}, this.initialOptions), ...this.updateArgs);
    },
    drawSquare (x, y, width, height) {
      const square = this.chart.renderer.rect(x, y, width, height, 5)
        .attr({
          'stroke-width': 4,
          stroke: 'rgb(0, 163, 199)',
          'stroke-dasharray': 12,
          fill: 'transparent',
          zIndex: 3
        });
      return square.add().element;
    }
  },
  mounted() {
    // Check wheather the chart configuration object is passed, as well as the constructor is valid
    if (this.initialOptions && Highcharts[this.constructorType]) {
      this.makeChart(this.initialOptions);
    } else {
      !this.initialOptions
        ? console.warn('The "options" parameter was not passed.')
        : console.warn(
            `'${this.constructorType}' constructor-type is incorrect. Sometimes this error is casued by the fact, that the corresponding module wasn't imported.`
          );
    }
  },
  watch: {
    initialOptions () {
      this.drawChart();
    }
  },
  beforeDestroy() {
    // Destroy chart if exists
    if (this.chart) {
      this.chart.destroy();
    }
  }
};
export default HighchartsVueComponent;
</script>
