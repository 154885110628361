








import { Component, Prop, Vue } from "vue-property-decorator";
import highchartsCustom from "@/components/highchartsCustom.vue";
import baseChart from "@/components/chartSettings";
import Highcharts, { Series, XAxisOptions, YAxisOptions, SeriesOptionsType } from 'highcharts';

@Component({
  components: {
    highchartsCustom,
  },
})
export default class Home extends Vue {
  @Prop({ required: true }) readonly chartData: any;
  @Prop({ required: true }) readonly title: string;
  @Prop({ required: true }) readonly yAxisTitle: string;
  @Prop({ required: true }) readonly annotationLabels: Array<any>;

  get chartOptions(): Highcharts.Options  {
    let chartOptions: Highcharts.Options  = {};
    chartOptions = baseChart.getChartOptions();

    chartOptions.chart.type = "waterfall";
    chartOptions.title.text = this.title;
    (chartOptions.yAxis as YAxisOptions).title.text = this.yAxisTitle;
    (chartOptions.xAxis as XAxisOptions).labels.enabled = true;
    (chartOptions.xAxis as XAxisOptions).type = "category";
    (chartOptions.yAxis as YAxisOptions).gridLineWidth = 0.3;
    chartOptions.legend.enabled = false;
    chartOptions.tooltip.enabled = false;

    if (this.annotationLabels) {
      chartOptions.annotations[0].labels = this.annotationLabels;
    }

    const data = [];

    Object.keys(this.chartData).forEach((category) => {
      let objToAdd;
      if (category === "Low Cost High Renewables") {
        objToAdd = {
          name: category,
          y: this.chartData[category],
          color: "rgb(233, 63, 111)",
          isSum: true,
        };
      } else {
        objToAdd = {
          name: category,
          y: this.chartData[category],
        };
      }

      data.push(objToAdd);
    });

    chartOptions.series.push({
        color: '#f7a600',
        data: data,
        dataLabels: {
          enabled: true,
          verticalAlign: 'bottom',
          y: 20,
          color: 'black',
          formatter: function () {
                    if (this.point.isSum) {
                    return '-48.1';
                }
                return this.y;
            },
        },
      } as SeriesOptionsType);

    chartOptions.plotOptions.series['pointWidth'] = 60;

    return chartOptions;
  }
}
