

































// eslint-disable-next-line no-irregular-whitespace
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
  },
})
export default class CategoriesModelling extends Vue {}
