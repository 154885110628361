/* eslint-disable */
var defaultFontStyle = "Quicksand, sans-serif";
var defaultHeaderSize = "2rem";
var defaultFontSize = "1.3rem";
var defaultLegendItemSize = "1rem";
var defaultChartColor = "#000";
var defaultFontColor = '#000'
var defaultAxisLineColor = "rgb(141, 168, 173)";

export default {
  getChartOptions() {
    return {
      chart: {
        animation: true,
        type: "",
        events: {
          load: null,
          redraw: null
        },
        // width: 800,
        spacingBottom: 10,
        spacingTop: 20,
        backgroundColor: "transparent",
        style: {
          fontFamily: defaultFontStyle,
          color: "#fff",
          fontWeight: "bold",
        },
        zoomType: "x",
        panning: true,
      },
      boost: {
        enabled: false
      },
      time: {
        useUTC: false
      },
      legend: {
        borderRadius: 6,
        borderWidth: 0,
        backgroundColor: defaultChartColor,
        bubbleLegend: {
          enabled: true,
          borderWidth: 1,
          connectorDistance: 40,
          ranges: [{
              color: "rgba(233, 63, 111, 0.7)"
            },
            {
              color: "rgba(247, 166, 0, 0.7)"
            },
            {
              color: "rgba(0, 163, 199, 0.7)"
            }
          ]
        },
        enabled: true,
        itemStyle: {
          color: defaultChartColor,
          fontSize: defaultLegendItemSize,
          fontWeight: "bold"
        },
        width: "100%",
        layout: "horizontal",
        verticalAlign: "bottom",
        align: "center",
        x: 70,
        maxHeight: 60
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        },
        spline: {
          marker: {
            enabled: false
          }
        },
        series: {
          animation: true,
          events: {
            hide: null,
            show: null
          },
          boostThreshold: 1000,
          borderWidth: 0,
          stacking: 'normal',
        },
        scatter: {
          marker: {
            symbol: 'circle',
          },
          cursor: 'pointer',
        },
      },
      series: [],
      title: {
        style: {
          color: defaultChartColor,
          fontSize: defaultHeaderSize
        },
        text: ""
      },
      credits: {
        href: "",
        text: "",
        style: {
          color: defaultChartColor,
          fontSize: defaultFontSize
        }
      },
      annotations: [{
				labels: [],
				labelOptions: {
					useHTML: true,
				}
			}],
      tooltip: {
        animation: true,
        backgroundColor: "rgba(5, 31, 79, 1)",
        borderRadius: 15,
        borderWidth: 1,
        enabled: true,
        followPointer: true,
        style: {
          color: 'White',
          fontSize: "1.2rem",
          fontWeight: 600,
        },
        formatter: function () {
          var returnText = `${this.x} <br/>`;
          this.points.forEach((point) => {
            returnText += `<span style="color:${point.color}">\u25CF</span>${
              point.point.series.name
            }: ${(Math.round(point.y * 10) / 10)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MW <br />`;
          });
          return returnText;
        },
        shared: true,
        useHTML: true
      },
      navigation: {
        buttonOptions: {
          verticalAlign: "top",
          y: -20
        }
      },
      yAxis: {
        plotLines: [{
          value: 0,
          width: 1,
          color: '#aaa',
          zIndex: 10
        }],
        labels: {
          style: {
            color: defaultChartColor,
            fontSize: defaultFontSize
          }
        },
        title: {
          text: "",
          style: {
            color: defaultChartColor,
            fontSize: defaultFontSize
          }
        }
      },
      xAxis: {
        labels: {
          style: {
            color: defaultChartColor,
            fontSize: defaultFontSize
          }
        },
        title: {
          style: {
            color: defaultChartColor,
            fontSize: defaultFontSize
          }
        }
      }
    };
  }
};
