




















































































import { Component, Vue } from "vue-property-decorator";
import WaterfallChart from "@/components/charts/base/WaterfallChart.vue";
import waterfallJson from "@/assets/json_data/new/Waterfall.json";

@Component({
    components: {
        WaterfallChart,
    },
})
export default class MoneySaving extends Vue {
    get chartData() {
        return waterfallJson;
    }

    private selectedScenario = 1;
}
