






import { Component, Vue } from 'vue-property-decorator';
@Component
export default class BackToTop extends Vue {


    get showButton(): boolean{
        return this.$store.state.ui.showDownBtn;
    }

}
