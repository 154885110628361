



















import { Component, Vue } from "vue-property-decorator";

import BaseResult from "@/components/Steps/BaseResult.vue";
import IncreaseOffshore from "@/components/Steps/IncreasedOffshore.vue";
import IncreasedLowCarbon from "@/components/Steps/IncreasedLowCarbon.vue";
import IncreaseLongDuration from "@/components/Steps/IncreasedLongDuration.vue";
import MarketReforms from "@/components/Steps/MarketReforms.vue";
import CoordinatedOffshore from "@/components/Steps/CoordinatedOffshore.vue";
import FinalResult from "@/components/Steps/FinalResult.vue";

@Component({
    components: {
        BaseResult,
        IncreaseOffshore,
        IncreasedLowCarbon,
        IncreaseLongDuration,
        MarketReforms,
        CoordinatedOffshore,
        FinalResult,
    },
})
export default class EndResult extends Vue {
    componentArray = [
        "BaseResult",
        "IncreaseOffshore",
        "IncreasedLowCarbon",
        "IncreaseLongDuration",
        "MarketReforms",
        "CoordinatedOffshore",
        "FinalResult",
    ];

    get selectedStep(): boolean {
        return this.$store.state.ui.selectedStep;
    }
}
