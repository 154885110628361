








import { Component, Prop, Vue } from "vue-property-decorator";
import highchartsCustom from "@/components/highchartsCustom.vue";
import baseChart from "@/components/chartSettings";
import Highcharts, { YAxisOptions, XAxisOptions } from "highcharts";

@Component({
  components: {
    highchartsCustom,
  },
})
export default class ColumnRangeChart extends Vue {
  @Prop({ required: true }) readonly chartData: any;
  @Prop({ required: true }) readonly tooltipUnits: string;
  @Prop() readonly title: string;
  @Prop() readonly yAxisTitle: string;
  @Prop() readonly xAxisTitle: string;
  @Prop() readonly annotationLabels: any[];

  get chartOptions(): Highcharts.Options {
    let chartOptions: Highcharts.Options = baseChart.getChartOptions();
    chartOptions.chart.marginRight = 0;
    chartOptions.chart.type = "columnrange";
    chartOptions.chart.inverted = true;
    chartOptions.title.text = this.title;
    (chartOptions.xAxis as XAxisOptions).title.text = this.yAxisTitle;
    (chartOptions.yAxis as YAxisOptions).title.text = this.xAxisTitle;
    chartOptions.legend = {
      enabled: false,
    };
    (chartOptions.xAxis as XAxisOptions).labels.enabled = true;
    (chartOptions.xAxis as XAxisOptions).gridLineWidth = 0.3;
    (chartOptions.xAxis as XAxisOptions).categories = this.chartData.categories;

    chartOptions.tooltip.formatter = function () {
      var returnText = `${this.x} <br/>`;
      this.points.forEach((point) => {
        returnText += `<span style="color:${point.color}">\u25CF</span>${
          point.point.series.name
        }: ${(Math.round(point.y * 10) / 10)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${(point.series.userOptions as any).ySuffix} <br />`;
      });
      return returnText;
    };

    (chartOptions as any).series = [
      {
        name: "Costs",
        data: this.chartData.values,
        type: "columnrange",
        ySuffix: this.tooltipUnits,
      },
    ];

    chartOptions.plotOptions = {
      column:{
        groupPadding: 0
      }
    }

    return chartOptions;
  }
}
