













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { HeadlineDisplay },
})
export default class HeadlineDisplay extends Vue {
  @Prop({ default: "sse-green" }) color: string;

  get setColor() {
    switch (this.color) {
      case "sse-green":
        return "sse-green";
      case "sse-orange":
        return "sse-orange";
      case "sse-alternative":
        return "sse-alternative";
      case "sse-secondary":
        return "sse-secondary";
      case "sse-lightGreen":
        return "sse-lightGreen";
      default:
        return this.color;
    }
  }
}
