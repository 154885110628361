




















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { required, minLength, email } from 'vuelidate/lib/validators'
import { Validations } from 'vuelidate-property-decorators';
import { v4 as uuidv4 } from 'uuid';
import Vuelidate from 'vuelidate';
// @ts-ignore
Vue.use(Vuelidate);

@Component
export default class ContactUsForm extends Vue {
    public clientDetails: any = {
        firstname: "",
        lastname: "",
        email: "",
        jobTitle: "",
        country: "",
        whereHeardFrom: "",
        insight: false,
        webinars: false,
        setUpMeetings: false,
    }

    public isSubmitted = false;

    @Validations()
    validations = {
        clientDetails: {
            firstname: {
                required,
                minLength: minLength(2)
            },
            lastname: {
                required,
                minLength: minLength(2)
            },
            email: {
                required,
                email
            },
            jobTitle: {
                required,
                minLength: minLength(2)
            },
            companyName: {
                required,
                minLength: minLength(2)
            },
            whereHeardFrom: {
                required,
            }

        }
    }

    handleSubmit(): void {
        // @ts-ignore
        this.$v.$touch();
        // @ts-ignore
        if (!this.$v.$invalid) {

            const formData:any = {};
            formData.id = uuidv4();
            formData.Firstname = this.clientDetails.firstname;
            formData.Surname = this.clientDetails.lastname;
            formData.Company = this.clientDetails.companyName;
            formData.Question = this.clientDetails.whereHeardFrom;
            formData.Email = this.clientDetails.email;
            formData.JobTitle = this.clientDetails.jobTitle;

            formData.SignUp = [];
            if (this.clientDetails.insight) formData.SignUp.push('Insight');
            if (this.clientDetails.webinars) formData.SignUp.push('Webinars');
            if (this.clientDetails.setUpMeetings) formData.SignUp.push('SetUpMeetings');

            formData.Type = 'SSE NetZero';
            fetch('https://enact-functionapp-datachecks.azurewebsites.net/api/EnactLiteRequest',
                {
                    method: 'POST',
                    body: JSON.stringify(formData),
                    mode: 'no-cors',
                },
            ).then((response) => {
                this.isSubmitted = true;
            }).catch((error) => {
                this.isSubmitted = false;
            });
        }

    }


    formReset() {
        this.clientDetails = {
            firstname: "",
            lastname: "",
            email: "",
            companyName: "",
            jobTitle: "",
            whereHeardFrom: "",
            insight: false,
            webinars: false,
            setUpMeetings: false,
        }
        // @ts-ignore
        this.$v.$reset();
        this.isSubmitted = false;
    }
}
