import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ui: {
      hideVertical: true,
      selectedStep: 0,
      showDownBtn: true,
    },
    data: {
      scenarioMapping: [
        "Current Trajectory",
        "Low Cost High Renewable (LCHR)",
        "LCHR - without limited nuclear",
        "LCHR - without high offshore",
        "LCHR - without long duration storage",
        "LCHR - without high Gas CCS",
        "LCHR - without market reform",
        "LCHR - offshore replaces nuclear",
        "LCHR - gas CCS replaces nuclear",
        "LCHR - no unabated gas post 2035",
      ],
      fuelColourMapping: {
        Nuclear: "rgb(255,236,92)",
        CCGT: "rgb(149,148,232)",
        Coal: "rgb(0,0,0)",
        Biomass: "rgb(165,42,42)",
        "CHP Gas": "rgb(253,203,120)",
        "Gas CCS": "rgb(139,69,19)",
        "Other RES": "rgb(141,168,173)",
        "Onshore Wind": "rgb(92,139,34)",
        "Offshore Wind": "rgb(0,100,0)",
        Solar: "rgb(247,166,0)",
        Interconnector: "rgb(189,183,107)",
        "Other Storage": "rgb(126,195,232)",
        "Battery Storage": "rgb(220,20,60)",
        Peaking: "rgb(255,236,92)",
        Hydro: "rgb(100,150,200)",

        Basecase: "rgb(247,166,0)",
        "Basecase (excl negative)": "rgb(247,166,0)",
      },
    },
  },
  mutations: {
    updateVerticalDisplay(state, showVertical: boolean) {
      Vue.set(state.ui, "hideVertical", showVertical);
    },
    updateSelectedStep (state, selectedStep : number) {
      Vue.set(state.ui, "selectedStep", selectedStep);
    },

    updateShowDownBtn(state, showBtn: boolean) {
      Vue.set(state.ui, 'showDownBtn', showBtn )
    }
  },
  actions: {},
  modules: {},
});
