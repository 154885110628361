// interface DataByCategory {
//   [key: string]: number[];
// }

interface dataType {

    dataByCategory: any,
    years: number[],

}

interface dataTypeSecondary {
  categories: string[],
  values: number[][],
}

export default class ChartOptions {
  data: dataType | dataTypeSecondary;
  title: string;
  summary: string;
  yAxisTitle: string;
  tooltipUnits: string;

  constructor(data: dataType | dataTypeSecondary, title = '', summary = '', yAxisTitle = '', tooltipUnits = '') {
    this.data = data;
    this.title = title;
    this.summary = summary;
    this.yAxisTitle = yAxisTitle;
    this.tooltipUnits = tooltipUnits;
  }
}
