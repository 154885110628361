












import { Component, Vue } from "vue-property-decorator";
@Component
export default class BaseModal extends Vue {
  closeModal() {
    return this.$emit("close", false);
  }
}
