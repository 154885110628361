















































































import { Component, Vue } from "vue-property-decorator";
import StackedChart from "@/components/charts/base/StackedChart.vue";
import ColumnRangeChart from "@/components/charts/base/ColumnRangeChart.vue";
import scenarioDataJson from "@/assets/json_data/new/1.json";
import ChartOptions from "@/model/ChartOptions";
@Component({
    components: {
        StackedChart,
        ColumnRangeChart,
    },
})
export default class OverallSummary extends Vue {
    showChart = false;
    chosenChart = "";

    chartData = {
        systemCosts: new ChartOptions(
            scenarioDataJson["SystemCosts"],
            "Change in System Costs. £(real,2020)bn"),
    };
}
