








import { Component, Prop, Vue } from "vue-property-decorator";
import highchartsCustom from "@/components/highchartsCustom.vue";
import baseChart from "@/components/chartSettings";
import Highcharts, { XAxisOptions, YAxisOptions } from "highcharts";

@Component({
  components: {
    highchartsCustom,
  },
})
export default class StackedChart extends Vue {
  @Prop({ required: true }) readonly chartType: string;
  @Prop({ required: true }) readonly chartData: any;
  @Prop() readonly title: string;
  @Prop({ required: true }) readonly yAxisTitle: string;
  @Prop({ required: true }) readonly tooltipUnits: string;
  @Prop() readonly xAxisTitle: string;
  @Prop() readonly annotationLabels: any[];
  @Prop() readonly showLegend: boolean;

  get chartOptions(): Highcharts.Options {
    let chartOptions: Highcharts.Options = baseChart.getChartOptions();

    chartOptions.chart.marginTop = 10;
    chartOptions.chart.marginRight = 0;
    chartOptions.chart.marginLeft = 75;
    chartOptions.chart.type = this.chartType;
    chartOptions.title.text = this.title;
    (chartOptions.yAxis as YAxisOptions).title.text = this.yAxisTitle;
    (chartOptions.xAxis as XAxisOptions).title.text = this.xAxisTitle;
    chartOptions.legend = {
      enabled: this.showLegend,
      // align: "right",
      // verticalAlign: "bottom",
      // layout: "vertical",
      // // x: 0,
      // // y: 100,
      // itemStyle: {
      //   color: "black",
      // },
      // symbolWidth: 40,
    };
    (chartOptions.xAxis as XAxisOptions).labels.enabled = true;
    (chartOptions.yAxis as YAxisOptions).gridLineWidth = 0.3;
    chartOptions.tooltip.formatter = function () {
      var returnText = `${this.x} <br/>`;
      this.points.forEach((point) => {
        returnText += `<span style="color:${point.color}">\u25CF</span>${
          point.point.series.name
        }: ${(Math.round(point.y * 10) / 10)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${(point.series.userOptions as any).ySuffix} <br />`;
      });
      return returnText;
    };

    let plotOptions;
    switch (this.chartType) {
      case "area":
        plotOptions = {
          series: {
            marker: { enabled: false },
            groupPadding: 0,
            animation: {
              duration: 1500,
            }
          },
          area: {
            lineWidth: 0,
            stacking: "normal",
          },
        };
        break;
      case "column":
        plotOptions = {
          series: {
            marker: { enabled: false },
            groupPadding: 0,
            animation: {
              duration: 1500,
            }
          },
          column: {
            lineWidth: 0,
            stacking: "normal",
          },
        };
        break;
      case "line":
        plotOptions = {
          series: {
            marker: { enabled: false },
            groupPadding: 0,
            borderWidth: 0,
            animation: {
              duration: 1500,
            }
          },
          line: {
            // lineWidth: 1,
          },
        };
        break;
    }

    chartOptions.plotOptions = plotOptions;

    const yearStrings = [];
    this.chartData.years.forEach((year) => {
      yearStrings.push(year.toString());
    });
    (chartOptions.xAxis as XAxisOptions).categories = yearStrings;

    const colours = this.$store.state.data.fuelColourMapping;
    Object.keys(this.chartData.dataByCategory).reverse().forEach((category) => {
      let objToAdd;
      if (category == 'Total') {
        objToAdd = {
          name: category,
          data: this.chartData.dataByCategory[category],
          color: colours[category],
          type: 'line',
          ySuffix: this.tooltipUnits,
        };
      }
      else {
        objToAdd = {
          name: category,
          data: this.chartData.dataByCategory[category],
          color: colours[category],
          type: this.chartType,
          ySuffix: this.tooltipUnits,
        };
      }

      chartOptions.series.push(objToAdd);
    });

    chartOptions.series.forEach(s => {
      if (s.name === 'CO2 Intensity (excl BECCS)') (s as any).dashStyle = "longDash";
    })

    return chartOptions;
  }
}
