













import { Component, Vue } from "vue-property-decorator";
import OverallSummary from '@/components/SummaryResultsStep/OverallSummary.vue'

@Component({
    components: {
    OverallSummary,
    },
})
export default class OverallSummaryResults extends Vue {

}
