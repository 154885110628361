










































































import { Component, Vue} from "vue-property-decorator";
// import CustomButton from '@/components/CustomButton.vue';

@Component({
  components:{
    // CustomButton
  }
})
export default class WhatWeWantToDeliver extends Vue {



  scrollTo(): void {
        document.getElementById('headlineAnalysis').scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }

get showDownBtn (): boolean {
  return this.$store.state.ui.showDownBtn;
}

}

