




























import { Component, Vue } from "vue-property-decorator";

import lightning from "@/components/lightning.vue";
import sseHeader from "@/components/sseHeader.vue";
import navigation from "@/components/navigation.vue";
import BackToTop from "@/components/BackToTop.vue";
import Home from "@/views/Home.vue";

@Component({
    components: {
        lightning,
        sseHeader,
        navigation,
        BackToTop,
        Home,
    },
})
export default class App extends Vue {

    public scrollResize = false;
    public pageScrollPosition = false;

    get overflowIsHidden(): boolean {
        return this.$store.state.ui.hideVertical;
    }

    resizeHeaderOnScroll(): void {
        this.scrollResize = (this.$refs.mainContent as HTMLElement).scrollTop > 100;
        this.pageScrollPosition = (this.$refs.mainContent as HTMLElement).scrollTop < 150;

        this.$store.commit('updateShowDownBtn', this.pageScrollPosition);
    }


    backToTop (): void {
        (this.$refs.mainContent as HTMLElement).scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    backHome () : void {
        this.backToTop();
        (this.$refs.home as any).end();
    }

}
