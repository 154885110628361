import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import VueGtag from "vue-gtag";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faWind,
  faGavel,
  faRadiation,
  faArrowDown,
  faArrowUp,
  faArrowRight,
  faPiggyBank,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCaretDown, faExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons";
import { faCreativeCommonsZero, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimes, faChevronLeft, faChevronDown, faChevronUp , faPhoneLaptop } from "@fortawesome/pro-light-svg-icons";

library.add(
  faWind,
  faGavel,
  faRadiation,
  faArrowDown,
  faArrowUp,
  faArrowRight,
  faCaretDown,
  faCreativeCommonsZero,
  faTimes,
  faChevronLeft,
  faTwitter,
  faLinkedin,
  faPiggyBank,
  faChevronDown,
  faPhoneLaptop,
  faChevronUp,
  faExternalLinkAlt,
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-1M402E8798" }
});


new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
