



























import { Component, Vue } from "vue-property-decorator";
import Categories from "@/components/SystemCostFramework/Categories.vue";
import CategoriesDetails from "@/components/SystemCostFramework/CategoriesDetails.vue";

@Component({
  components: {
    Categories,
    CategoriesDetails,
  },
})
export default class SystemCostFramework extends Vue {
  activeSlide = "category";
  componentToShow = "";

  showMoreDetails(): void {
    this.activeSlide = "moreDetails";
  }
  goBack(): void {
    this.activeSlide = "category";
  }
  get showSlideComponent(): string {
    this.componentToShow = "Categories";
    // if (this.activeSlide === "category") {
    //   this.componentToShow = "Categories";
    // }
    if (this.activeSlide === "moreDetails") {
      this.componentToShow = "CategoriesDetails";
    }
    return this.componentToShow;
  }
}
