



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {},
})
export default class SseHeader extends Vue {
    @Prop(Boolean) scrollResize: boolean;

    get overflowIsHidden(): boolean {
        return this.$store.state.ui.hideVertical;
    }

    get sseLogoSwitch(): string {
        return this.scrollResize ? '/svgs/sse_logo_white_new.svg' : '/svgs/sse_logo_new.svg';
    }

}
