










// eslint-disable-next-line no-irregular-whitespace
import { Component, Vue } from "vue-property-decorator";
import TabWrapper from "@/components/TabWrapper.vue";
import GenerationCost from '@/components/SystemCostFramework/GenerationCost.vue';
import BalancingCost from '@/components/SystemCostFramework/BalancingCost.vue';
import CapacityAdequacy from '@/components/SystemCostFramework/CapacityAdequacy.vue';
import NetworkCost from '@/components/SystemCostFramework/NetworkCost.vue';
import InterconnectorCost from '@/components/SystemCostFramework/InterconnectorCost.vue';

@Component({
  components: {
    TabWrapper,
    GenerationCost,
    BalancingCost,
    CapacityAdequacy,
    NetworkCost,
    InterconnectorCost,
  },
})
export default class CategoriesDetails extends Vue {
  activeTab = 0;


  tabContent = [
    {
      name: "Generation costs",
      color: "#f7a600",
      component: `GenerationCost`,
    },
    {
      name: "Balancing costs",
      color: "#f7a600",
      component: `BalancingCost`,
    },
    {
      name: "Capacity costs",
      color: "#e93f6f",
      component: `CapacityAdequacy`,
    },
    {
      name: "Network costs",
      color: "#002f5f",
      component: `NetworkCost`,
    },
    {
      name: "Interconnector costs",
      color: "#002f5f",
      component: `InterconnectorCost`,
    },
  ];

  changeTab(index: number) {
    this.activeTab = index;
  }

  get contentToShow() {
    return this.tabContent[this.activeTab].component;
  }
}
