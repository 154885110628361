

















































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import StackedChart from "@/components/charts/base/StackedChart.vue";
import ColumnRangeChart from "@/components/charts/base/ColumnRangeChart.vue";
// import scenarioDataJson from "@/assets/json_data/new/1.json";
import ChartOptions from "@/model/ChartOptions";
@Component({
    components: {
        StackedChart,
        ColumnRangeChart,
    },
})
export default class IncreaseOffshore extends Vue {
    @Prop({ required: true }) Title: string;
    @Prop({ required: true }) Color: string;
    @Prop({ required: true }) StepNumber: number;
    @Prop({ required: true }) CostBenefit: number;

    showChart = false;
    chartKey = 0;
    chosenChart = "";

    chartData = {};

    chartsLoaded = false;

    async mounted(): Promise<void> {
        this.chartsLoaded = true;

        const scenarioDataJson = await import(`@/assets/json_data/new/${this.StepNumber}.json`);

        let intensityTitle;
        let generationTitle;
        let capacityTitle;
        let costsTitle;

        if (this.StepNumber === 0) {
            intensityTitle = 'Carbon Emissions, m tonnes';
            generationTitle = 'Generation, TWh';
            capacityTitle = 'Capacity, GW';
            costsTitle = 'System Cost, £(real, 2020)bn';
        }
        else {
            intensityTitle = 'Change in Carbon Emissions, m tonnes';
            generationTitle = 'Change in Generation, TWh';
            capacityTitle = 'Change in Capacity, GW';
            costsTitle = 'Change in System Cost, £(real, 2020)bn';
        }

        this.chartData = {
            intensity: new ChartOptions(scenarioDataJson["CarbonEmissionsByCase"], intensityTitle,'','Change in Carbon Emissions, m tonnes', 'm tonnes'),
            generation: new ChartOptions(scenarioDataJson["GenerationByTechnology"], generationTitle,'','Change in Generation, TWh', 'TWh'),
            capacity: new ChartOptions(scenarioDataJson["CapacityByTechnology"], capacityTitle,'','Change in Capacity, GW', 'GW'),
            costs: new ChartOptions(scenarioDataJson["Costs"], costsTitle,'','Change in System Cost, £(real, 2020)bn', 'bn'),
            systemCosts: new ChartOptions(scenarioDataJson["SystemCosts"],'','','','bn'),
            capacitySummary: new ChartOptions(scenarioDataJson["CapacitySummary"],'','','','GW'),
            generationSummary: new ChartOptions(scenarioDataJson["GenerationSummary"],'','','','TWh'),
        };
    }
    // eslint-disable-next-line
    get chartInView(): any {
        return this.chartData[this.chosenChart];
    }

    showChartDisplay(chart: string): void {
        this.chartKey++;
        this.showChart = true;
        this.chosenChart = chart;
    }

    viewSummary(): void {
        this.chosenChart = null;
        this.showChart = false;
    }
}
