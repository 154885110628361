





































import { Component, Prop, Vue } from "vue-property-decorator";
import CustomButton from '@/components/CustomButton.vue';

@Component({
    components: {
        CustomButton,
    },
})

export default class Navigation extends Vue {
    public currentRoute = 0;
    public routes = [{
        id: 'deliver',
        next: 'What we found out',
    }, {
        id: 'headlineAnalysis',
        next: 'How did we model this',
    }, {
        id: 'modelling',
        next: 'How much money will we save',
    }, {
        id: 'moneySaving',
        next: 'The starting point',
    }, {
        id: 'results',
        next: 'Step 1',
        step: 0,
    }, {
        id: 'results',
        next: 'Step 2',
        step: 1,
    }, {
        id: 'results',
        next: 'Step 3',
        step: 2,
    }, {
        id: 'results',
        next: 'Step 4',
        step: 3,
    }, {
        id: 'results',
        next: 'Step 5',
        step: 4,
    }, {
        id: 'results',
        next: 'All together',
        step: 5,
    }, {
        id: 'results',
        next: 'Summarise results',
        step: 6,
    }, {
        id: 'summaryResult',
        next: 'Our conclusions',
    }, {
        id: 'conclusion',
        next: 'Get in touch',
    }, {
        id: 'contact',
        next: 'What we found out',
    }];
    private isScrolling = false;

    get overflowIsHidden(): boolean {
        return this.$store.state.ui.hideVertical;
    }

    handleKeypress (e: KeyboardEvent): void {
        // switch (e.code) {
        //     case 'ArrowUp':
        //         this.scrollPrevious();
        //         return;
        //     case 'ArrowDown':
        //         this.scrollNext();
        //         return;
        //     default:
        //         break;
        // }

    }
    created () {
        window.addEventListener('wheel', this.scrollChange);
        window.addEventListener('keydown', this.handleKeypress)
    }
    destroyed() {
        window.removeEventListener('wheel', this.scrollChange);
        window.removeEventListener('keydown', this.handleKeypress);

    }

    scrollChange(e: WheelEvent):void {
        // NEEDS DEBOUNCE
        // if (!this.isScrolling) {
        //     this.isScrolling = true;
        //     if (e.deltaY > 0) this.scrollNext();
        //     if (e.deltaY < 0) this.scrollPrevious();
        //     setTimeout(() => {
        //         this.isScrolling = false;
        //     }, 1000);
        // }
    }

    scrollPrevious(): void {
        this.currentRoute -= 1;
        this.scrollSection();
    }

    scrollNext(): void {
        this.currentRoute += 1;
        this.scrollSection();
    }

    scrollSpecificSection(id: number): void {
        this.currentRoute = id;
        this.scrollSection();
    }

    scrollSection(): void {
        const route = this.routes[this.currentRoute];
        if (route.step !== undefined) this.$store.commit('updateSelectedStep', route.step);
        if(route.step === undefined || route.step === 0 || route.step === 6) this.scrollTo(route.id);
    }
    scrollTo(refName: string): void {
        document.getElementById(refName).scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
}
