






















// eslint-disable-next-line no-irregular-whitespace
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TabWarpper extends Vue {
  @Prop() tabContent: any[];
  @Prop({ default: 0 }) activeTab: number;

  // FEEDBACK - calling a computed setActiveTab seems strange as you're not setting anything. Also you are setting classes which are very specific and therefore not reuseable.

  changeTab(index: number) {
    this.$emit("changeTab", index);
  }
  get tabWidth() {
    return 100 / this.tabContent.length - 1;
  }
}
