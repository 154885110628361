















































































































import { Component, Vue } from 'vue-property-decorator';
import BaseModal from "@/components/BaseModal.vue";
import ContactUsForm from '@/components/ContactUsForm.vue'
import sseFooter from '@/components/sseFooter.vue'
@Component({
    components: { BaseModal, ContactUsForm, sseFooter }
})
export default class GetInTouch extends Vue {
    public showForm = false;

    showModal(): void {
        this.showForm = true;
    }
    closeModal(): void {
        this.showForm = false;
    }
}
