





























import { Component, Prop, Vue } from "vue-property-decorator";

import StepBase from "@/components/Steps/StepBase.vue";

@Component({
  components: {
    StepBase,
  },
})
export default class BaseResult extends Vue {

}
