







import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class CustomButton extends Vue {
@Prop() bottom: boolean;
}
