








































































import { Component, Vue } from "vue-property-decorator";

import cityBgHome from "@/assets/svg/city-bg-home-wide-sky-alt.svg?inline";
import WhatWeWantToDeliver from "@/components/WhatWeWantToDeliver.vue";
import HeadlineAnalysisNew from "@/components/HeadlineAnalysisNew.vue";
import ModelingExplanation from "@/components/ModelingExplanation.vue";
import HeadlineDisplay from "@/components/HeadlineDisplay.vue";
import MoneySaving from "@/components/MoneySaving.vue";
import EndResult from "@/components/EndResult.vue";
import SummaryResult from "@/components/OverallSummaryResults.vue";
import OverallSummaryResults from "@/components/OverallSummaryResults.vue";
import Conclusion from "@/components/Conclusions.vue";
import GetInTouch from '@/components/GetInTouch.vue';
// import FinalLinks from '@/components/FinalLinks.vue';

@Component({
    components: {
        cityBgHome,
        WhatWeWantToDeliver,
        HeadlineAnalysisNew,
        ModelingExplanation,
        HeadlineDisplay,
        MoneySaving,
        EndResult,
        SummaryResult,
        OverallSummaryResults,
        Conclusion,
        GetInTouch,
        // FinalLinks,
    },
})
export default class Home extends Vue {

    public hideIntro = false;
    get hideVertical(): boolean {
        return this.$store.state.hideVertical;
    }
    start(): void {
        this.hideIntro = true;
        setTimeout(() => {
            this.$store.commit("updateVerticalDisplay", false);
        }, 4000);
    }
    end(): void {
        this.hideIntro = false;
        setTimeout(() => {
            this.$store.commit("updateVerticalDisplay", true);
        }, 0);
    }


}
