






































































// eslint-disable-next-line no-irregular-whitespace
import { Component, Vue } from "vue-property-decorator";
import HeadlineDisplay from "@/components/HeadlineDisplay.vue";
@Component({
  components: {
    HeadlineDisplay,
  },
})
export default class SlideOne extends Vue {}
