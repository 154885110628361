


































































































































import { Component, Vue } from "vue-property-decorator";
import HeadlineDisplay from "@/components/HeadlineDisplay.vue";
import SystemCostFramework from "@/components/SystemCostFramework/SystemCostFramework.vue";
import CategoriesDetails from "@/components/SystemCostFramework/CategoriesModelling.vue";
import CustomButton from '@/components/CustomButton.vue'
import roadBendRightBottom from "@/assets/svg/road-bend-right-bottom.svg";

@Component({
    components: {
        HeadlineDisplay,
        SystemCostFramework,
        CategoriesDetails,
        CustomButton
    },
})
export default class ModelingExplanation extends Vue {
    public roadBendRightBottom = roadBendRightBottom;
    public activeTab = 0;
    // public showSlider = false;
    public findOutDetails = false;
    public showMoreDetails = false;
    public close = false;
    tabContent = [
        { name: "Generation Cost & Balance Cost", color: "#f7a600" },
        { name: "Capacity adequacy cost", color: "#e93f6f" },
        { name: "Network cost", color: "#002f5f" },
    ];

    changeTab(index: number) {
        this.activeTab = index;
    }

    showModal() {
        this.showMoreDetails = true;
    }

    findOutMore() {
        this.findOutDetails = true;
    }

    backToDefaultView() {
        this.findOutDetails = false;
    }
    closeModal() {
        this.showMoreDetails = false;
    }
}
